<template>
  <div>
    <Base :title="title" titleIcon="el-icon-s-claim">
    <el-form ref="refForm" :model="form" label-width="180px" :rules="rules" :disabled="true">
      <el-tabs v-model="activeName" type="border-card" class="form-panel">
        <el-tab-pane label="计划书" name="second">
          <el-row>
            <el-col :span="24">
              <el-space :size="10" style="vertical-align: top">
                <el-form-item label="文保单位" prop="heritageUnit_Id">
                  <SelectUnit :setting="selectUnitSetting" v-model="form.heritageUnit_Id" @change="selectUnitChange">
                  </SelectUnit>
                </el-form-item>
                <el-form-item label="级别">
                  {{
      dicLabel(
        currentHeritage.heritageLevel_Code,
        heritageLevelDic
      )
    }}
                </el-form-item>
                <el-form-item label="类型">
                  {{
        dicLabel(
          currentHeritage.heritageClass_ID,
          dicHeritageClass
        )
      }}
                </el-form-item>
              </el-space>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="批次" prop="heritageBatch_ID">
                <el-select v-model="form.heritageBatch_ID">
                  <el-option v-for="item in dicHeritageBatch" :key="item.id" :value="parseInt(item.value)"
                    :label="item.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否革命文物" prop="isRevolution">
                <el-switch v-model="form.isRevolution" active-text="是" inactive-text="否" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="类型" prop="isSafeProject">
            <div>
              <el-radio-group v-model="form.isSafeProject">
                <el-radio :label="0">保护工程</el-radio>
                <el-radio :label="1">三防(安防消防防雷)</el-radio>
                <el-radio :label="2">考古调查勘探</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

          <template v-if="form.isSafeProject == 1">
            <el-form-item label="工程类型" prop="safeProjectType">
              <el-checkbox-group v-model="form.safeProjectType">
                <el-checkbox :label="item.value" v-for="item in scheduleTypeDic.filter(
      (p) => p.ext.projectType == form.isSafeProject
    )" :key="item.value">{{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item :label="form.isSafeProject == 0 ? '保护工程类型' : '考古目的'" prop="projectType">
              <el-radio-group v-model="form.projectType">
                <el-radio :label="item.value" v-for="item in scheduleTypeDic.filter(
      (p) => p.ext.projectType == form.isSafeProject
    )" :key="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </template>

          <el-row>
            <el-col>
              <el-space>
                <el-form-item label="所在位置" prop="cityId">
                  <el-select v-model="form.cityId" placeholder="市级" clearable>
                    <el-option v-for="item in dicCity" :key="item.id" :value="item.id" :label="item.name"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="" prop="countryId" label-width="0">
                  <el-select v-model="form.countryId" placeholder="区县" clearable>
                    <el-option v-for="item in dicTown" :key="item.id" :value="item.id" :label="item.name"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="" prop="address" label-width="0">
                  <el-input v-model="form.address" placeholder="镇（街道）村" style="width: 400px"></el-input>
                </el-form-item>
              </el-space>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="使用管理单位" prop="heritage_ManageOrg">
                <el-input v-model="form.heritage_ManageOrg" placeholder="（工程对象使用管理单位）"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上级主管部门" prop="heritage_OwnerSuperior">
                <el-input v-model="form.heritage_OwnerSuperior" placeholder="（工程对象上级主管部门）"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-alert title="资金相关数值单位均为万元，请填写实际数字，勿填写含其他文字描述" type="warning" show-icon />
          </el-form-item>

          <template v-if="form.isSafeProject == 1">
            <el-form-item prop="project_Name">
              <template #label>
                <!-- <el-tooltip
                    content="（文物保护单位名称+安防/消防/防雷工程
文物保护单位名称+安防/消防/防雷升级改造工程）
备注：如果只做局部，在文保单位名称之后+单体名称"
                  >
                    <i class="el-icon el-icon-warning" style="color: #409EFF;"></i>
                  </el-tooltip>-->
                工程对象名称
              </template>
              <el-input rows="3" type="textarea" v-model="form.project_Name" placeholder="（填写工程涉及文物名称及其具体位置、范围或结构单元）">
              </el-input>
            </el-form-item>
            <el-form-item label="工程依据">
              <el-form-item label="文物保护单位概况" prop="heritageUnitDesc">
                <el-input type="textarea" rows="5" v-model="form.heritageUnitDesc" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="安全防护设施设置概况" prop="projectProtectObject">
                <el-input type="textarea" v-model="form.projectProtectObject" rows="5"></el-input>
              </el-form-item>
              <el-form-item label="必要性分析" prop="projectNeedReason">
                <el-input type="textarea" rows="5" v-model="form.projectNeedReason" placeholder=""></el-input>
              </el-form-item>
            </el-form-item>

            <el-form-item label="以往历次工程实施情况">
              <div style="margin-bottom: 10px">
                <el-button type="primary" icon="el-icon-plus" @click="addHistoryProject">添加工程</el-button>
              </div>

              <el-table :data="form.historyProjects">
                <el-table-column label="工程对象名称" prop="name">
                  <template #default="{ row: item }">
                    <el-input v-model="item.name"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="实施时间" prop="startDate">
                  <template #default="{ row: item }">
                    <el-date-picker v-model="item.startDate"></el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column label="竣工验收时间" prop="endDate">
                  <template #default="{ row: item }">
                    <el-date-picker v-model="item.endDate"></el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column label="财政补助经费使用情况(万元)">
                  <el-table-column label="总经费" prop="amount">
                    <template #default="{ row: item }">
                      <el-input v-model="item.amount"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="已使用经费" prop="used">
                    <template #default="{ row: item }">
                      <el-input v-model="item.used"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="尚未使用经费" prop="unused">
                    <template #default="{ row: item }">{{
      ((item.amount || 0) - (item.used || 0)).toFixed(2)
    }}</template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label width="80px">
                  <template #default="{ row: item }">
                    <el-popconfirm placement="top-start" title="确认删除吗!?" @confirm="
      form.historyProjects.splice(
        form.historyProjects.findIndex((p) => p == item),
        1
      )
      ">
                      <template #reference>
                        <el-button type="danger" icon="el-icon-delete" size="mini"></el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-form-item label="工程内容">
              <el-form-item label="防护范围及重点" style="margin-bottom: 5px" prop="areaDesc">
                <el-input type="textarea" v-model="form.areaDesc" rows="5"></el-input>
              </el-form-item>
              <el-form-item label="工程技术路线" style="margin-bottom: 5px" prop="techLine">
                <el-input type="textarea" v-model="form.techLine" placeholder="（工程拟采用的技术措施、方式、方法、材料等）"
                  rows="5"></el-input>
              </el-form-item>
            </el-form-item>
            <el-form-item label="工程计划">
              <el-space>
                <el-form-item label="拟实施周期" prop="planDoPeroid">
                  <el-input v-model="form.planDoPeroid"></el-input>
                </el-form-item>
                <el-form-item label="计划竣工时间" prop="planEndTime">
                  <el-date-picker v-model="form.planEndTime"></el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="计划对外开放时间" label-width="280px" prop="planOpenTime">
                  <el-date-picker v-model="form.planOpenTime"></el-date-picker>
                </el-form-item> -->
              </el-space>
            </el-form-item>
            <el-form-item label="工程效果预测及文物影响评估" prop="resultReview">
              <el-input type="textarea" v-model="form.resultReview" placeholder="（简要说明工程预期效果并分析评估对文物造成的影响）" rows="5">
              </el-input>
            </el-form-item>

            <el-form-item label="工程经费估算(万元)">
              <el-space>
                <el-form-item label="总经费" prop="forecastAmount">
                  <el-input v-model="form.forecastAmount"></el-input>
                </el-form-item>
                <el-form-item label="前期经费" prop="forecastBeginAmount">
                  <el-input v-model="form.forecastBeginAmount"></el-input>
                </el-form-item>
                <el-form-item label="工程实施费" prop="forecastDoAmount">
                  <el-input v-model="form.forecastDoAmount"></el-input>
                </el-form-item>
              </el-space>
            </el-form-item>
            <el-form-item label="资金来源(万元)">
              <el-space>
                <el-form-item label="拟申请国家（省级）财政经费" prop="sourceCenterAmount">
                  <el-input v-model="form.sourceCenterAmount"></el-input>
                </el-form-item>
                <el-form-item label="地方财政经费" prop="sourceLocalAmount">
                  <el-input v-model="form.sourceLocalAmount"></el-input>
                </el-form-item>
                <el-form-item label="其他" prop="sourceElseAmount">
                  <el-input v-model="form.sourceElseAmount"></el-input>
                </el-form-item>
              </el-space>
            </el-form-item>
            <el-form-item label="其他需要说明的情况" prop="otherDesc">
              <el-input v-model="form.otherDesc" placeholder="（为确保工程效果，地方政府拟实施的配套工程简介及落实情况，后续运行管理保障等）" type="textarea"
                rows="5"></el-input>
            </el-form-item>

            <!-- <el-form-item label="工程防护范围及重点" prop="projectProtectArea">
              <el-input type="textarea" rows="5" v-model="form.projectProtectArea" placeholder="">
              </el-input>
            </el-form-item> -->
            <!-- <el-form-item label="工程防护对象" prop="projectProtectObject">
              <el-input type="textarea" rows="5" v-model="form.projectProtectObject"
                placeholder="(拟实施安全防护工程的重点防护对象，包含其内部展陈收藏的可移动文物（并说明收藏可移动文物的场所情况）、建筑本体、文物库房等)"></el-input>
            </el-form-item> -->

            <!-- <el-form-item label="工程估算（万元）" prop="forecastAmount">
              <el-input v-model="form.forecastAmount" placeholder="(万元)" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item prop="forecastAmountReason" label=" ">
              <el-input type="textarea" v-model="form.forecastAmountReason" placeholder="测算依据"></el-input>
            </el-form-item>
            <el-form-item label="拟采取的具体措施" prop="projectForecastDoThings">
              <el-input type="textarea" rows="5" v-model="form.projectForecastDoThings" placeholder="(包括物防措施、技防措施。根据项目存在风险列入拟采取的技防措施，例：
安防：适度设置的视频监控系统、入侵报警系统、电子巡更系统等
消防：消防给水系统、消防灭火设施、相适应的火灾自动报警系统、消防配电系统等
防雷：防直击雷装置)
"></el-input>
            </el-form-item>
            <el-form-item label="原有设施简况" prop="projectBaseThingsInfo">
              <el-input type="textarea" rows="5" v-model="form.projectBaseThingsInfo" placeholder="(主要介绍与所申报项目建设内容所对应的原有设施现状，包括原有设施建设年度、投资金额、已安装的设备简要说明、数量、设备目前使用功能情况、现有系统或设备存在的具体问题等，备注：最好单独附上现有设备检测报告、维护保养记录等。）
"></el-input>
            </el-form-item>
            <el-form-item label="项目实施保障条件" prop="projectDoCondition">
              <el-input type="textarea" rows="5" v-model="form.projectDoCondition" placeholder="(项目所在地地方政府对申请项目提供的相关政策条件、技术条件、管理机构、人员条件、资金条件和现场施工条件；安防、消防、防雷设施的管理运行条件等)
"></el-input>
            </el-form-item> -->
          </template>
          <template v-else-if="form.isSafeProject == 0">
            <el-form-item label="工程对象名称" prop="project_Name">
              <el-input v-model="form.project_Name" placeholder="（填写工程涉及文物名称及其具体位置、范围或结构单元）"></el-input>
            </el-form-item>

            <el-form-item label="工程依据">
              <el-form-item label="是否纳入文物保护规划" prop="isInProtectPlan">
                <el-space>
                  <el-switch v-model="form.isInProtectPlan" active-text="是" inactive-text="否" />
                  <el-input type="textarea" v-if="form.isInProtectPlan" v-model="form.inProtectPlanText"
                    placeholder="（若是，请说明规划要求）" style="width: 400px"></el-input>
                </el-space>
              </el-form-item>
              <el-form-item label="是否开展过前期勘察或研究" prop="isDoResearch">
                <el-space>
                  <el-switch v-model="form.isDoResearch" active-text="是" inactive-text="否" />
                  <el-input type="textarea" v-if="form.isDoResearch" v-model="form.doResearchText" style="width: 400px"
                    placeholder="（若是，请简要说明勘察或研究成果）"></el-input>
                </el-space>
              </el-form-item>
              <el-form-item label="必要性分析" prop="projectNeedReason">
                <el-input type="textarea" rows="5" v-model="form.projectNeedReason" placeholder=""></el-input>
              </el-form-item>
            </el-form-item>

            <el-form-item label="以往历次工程实施情况">
              <div style="margin-bottom: 10px">
                <el-button type="primary" icon="el-icon-plus" @click="addHistoryProject">添加工程</el-button>
              </div>

              <el-table :data="form.historyProjects">
                <el-table-column label="工程对象名称" prop="name">
                  <template #default="{ row: item }">
                    <el-input v-model="item.name"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="实施时间" prop="startDate">
                  <template #default="{ row: item }">
                    <el-date-picker v-model="item.startDate"></el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column label="竣工验收时间" prop="endDate">
                  <template #default="{ row: item }">
                    <el-date-picker v-model="item.endDate"></el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column label="财政补助经费使用情况(万元)">
                  <el-table-column label="总经费" prop="amount">
                    <template #default="{ row: item }">
                      <el-input v-model="item.amount"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="已使用经费" prop="used">
                    <template #default="{ row: item }">
                      <el-input v-model="item.used"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="尚未使用经费" prop="unused">
                    <template #default="{ row: item }">{{
      ((item.amount || 0) - (item.used || 0)).toFixed(2)
    }}</template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label width="80px">
                  <template #default="{ row: item }">
                    <el-popconfirm placement="top-start" title="确认删除吗!?" @confirm="
      form.historyProjects.splice(
        form.historyProjects.findIndex((p) => p == item),
        1
      )
      ">
                      <template #reference>
                        <el-button type="danger" icon="el-icon-delete" size="mini"></el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="工程内容">
              <el-form-item label="工程范围及规模" style="margin-bottom: 5px" prop="areaDesc">
                <el-input type="textarea" v-model="form.areaDesc" placeholder="（工程涉及面积、体量、时间，是否分期等）"
                  rows="5"></el-input>
              </el-form-item>
              <el-form-item label="工程技术路线" style="margin-bottom: 5px" prop="techLine">
                <el-input type="textarea" v-model="form.techLine" placeholder="（工程拟采用的技术措施、方式、方法、材料等）"
                  rows="5"></el-input>
              </el-form-item>
              <el-form-item label="展示内容策划构想" prop="showIdear">
                <el-input type="textarea" v-model="form.showIdear" placeholder="（内容策划核心理念，中心主题，分项主题，展示方式，受众分析等）"
                  rows="5">
                </el-input>
              </el-form-item>
            </el-form-item>
            <el-form-item label="工程计划">
              <el-space>
                <el-form-item label="拟实施周期" prop="planDoPeroid" label-width="120px">
                  <el-input v-model="form.planDoPeroid"></el-input>
                </el-form-item>
                <el-form-item label="计划竣工时间" prop="planEndTime" label-width="120px">
                  <el-date-picker v-model="form.planEndTime"></el-date-picker>
                </el-form-item>
                <el-form-item label="计划对外开放时间" label-width="140px" prop="planOpenTime">
                  <el-date-picker v-model="form.planOpenTime"></el-date-picker>
                </el-form-item>
              </el-space>
            </el-form-item>
            <el-form-item label="工程效果预测及文物影响评估" prop="resultReview">
              <el-input type="textarea" v-model="form.resultReview" placeholder="（简要说明工程预期效果并分析评估对文物造成的影响）" rows="5">
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="工程实施范围土地基本情况">
              <el-form-item label="土地现状" style="margin-bottom: 5px;" prop="landCondition">
                <el-input v-model="form.landCondition" placeholder="（简要介绍地面附着物的基本情况））"></el-input>
              </el-form-item>
              <el-form-item label="土地权属" style="margin-bottom: 5px;" prop="landOwnType">
                <el-space>
                  <el-radio-group v-model="form.landOwnType">
                    <el-radio label="国有">国有</el-radio>
                    <el-radio label="集体所有">集体所有</el-radio>
                  </el-radio-group>
                  <el-form-item label="实际管理部门" prop="landManager">
                    <el-input v-model="form.landManager" style="width:450px;"></el-input>
                  </el-form-item>
                </el-space>
              </el-form-item>
              <el-form-item label="土地性质" style="margin-bottom: 5px;" prop="landPropType">
                <el-space>
                  <el-radio-group v-model="form.landPropType">
                    <el-radio label="农用地">农用地</el-radio>
                    <el-radio label="建设用地">建设用地</el-radio>
                    <el-radio label="未利用地">未利用地</el-radio>
                  </el-radio-group>
                  <el-form-item prop="landPropMemo" label=" ">
                    <el-input type="textarea" v-model="form.landPropMemo" style="height:100%;width:510px;"
                      placeholder="备注：（是否为限制性用地？比如永久基本农田等）"></el-input>
                  </el-form-item>
                </el-space>
              </el-form-item>
            </el-form-item> -->
            <el-form-item label="工程经费估算（万元）">
              <el-space>
                <el-form-item label="总经费" prop="forecastAmount">
                  <el-input v-model="form.forecastAmount"></el-input>
                </el-form-item>
                <el-form-item label="前期经费" prop="forecastBeginAmount">
                  <el-input v-model="form.forecastBeginAmount"></el-input>
                </el-form-item>
                <el-form-item label="工程实施费" prop="forecastDoAmount">
                  <el-input v-model="form.forecastDoAmount"></el-input>
                </el-form-item>
              </el-space>
            </el-form-item>
            <el-form-item label="资金来源（万元）">
              <el-space>
                <el-form-item label="拟申请国家（省级）财政经费" prop="sourceCenterAmount">
                  <el-input v-model="form.sourceCenterAmount"></el-input>
                </el-form-item>
                <el-form-item label="地方财政经费" prop="sourceLocalAmount">
                  <el-input v-model="form.sourceLocalAmount"></el-input>
                </el-form-item>
                <el-form-item label="其他" prop="sourceElseAmount">
                  <el-input v-model="form.sourceElseAmount"></el-input>
                </el-form-item>
              </el-space>
            </el-form-item>
            <el-form-item label="其他需要说明的情况" prop="otherDesc">
              <el-input v-model="form.otherDesc" placeholder="（为确保工程效果，地方政府拟实施的配套工程简介及落实情况，后续运行管理保障等）" type="textarea"
                rows="5"></el-input>
            </el-form-item>
          </template>
          <template v-else-if="form.isSafeProject == 2">
            <el-form-item label="考古对象名称" prop="project_Name">
              <el-input v-model="form.project_Name" placeholder="（填写涉及文物名称及其具体位置、范围或结构单元）"></el-input>
            </el-form-item>

            <el-form-item label="工程依据">
              <el-form-item label="是否纳入文物保护规划" prop="isInProtectPlan">
                <el-space>
                  <el-switch v-model="form.isInProtectPlan" active-text="是" inactive-text="否" />
                  <el-input type="textarea" v-if="form.isInProtectPlan" v-model="form.inProtectPlanText"
                    placeholder="（若是，请说明规划要求）" style="width: 400px"></el-input>
                </el-space>
              </el-form-item>
              <el-form-item label="是否开展过考古工作" prop="isDoResearch">
                <el-space>
                  <el-switch v-model="form.isDoResearch" active-text="是" inactive-text="否" />
                  <el-input type="textarea" v-if="form.isDoResearch" v-model="form.doResearchText" style="width: 400px"
                    placeholder="（若是，请简要说明勘察或研究成果）"></el-input>
                </el-space>
              </el-form-item>
              <el-form-item label="必要性分析" prop="projectNeedReason">
                <el-input type="textarea" rows="5" v-model="form.projectNeedReason" placeholder=""></el-input>
              </el-form-item>
            </el-form-item>

            <el-form-item label="以往历次考古实施情况">
              <div style="margin-bottom: 10px">
                <el-button type="primary" icon="el-icon-plus" @click="addHistoryProject">添加工程</el-button>
              </div>

              <el-table :data="form.historyProjects">
                <el-table-column label="工程名称" prop="name">
                  <template #default="{ row: item }">
                    <el-input v-model="item.name"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="实施时间" prop="startDate">
                  <template #default="{ row: item }">
                    <el-date-picker v-model="item.startDate"></el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column label="竣工验收时间" prop="endDate">
                  <template #default="{ row: item }">
                    <el-date-picker v-model="item.endDate"></el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column label="财政补助经费使用情况(万元)">
                  <el-table-column label="总经费" prop="amount">
                    <template #default="{ row: item }">
                      <el-input v-model="item.amount"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="已使用经费" prop="used">
                    <template #default="{ row: item }">
                      <el-input v-model="item.used"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="尚未使用经费" prop="unused">
                    <template #default="{ row: item }">{{
      ((item.amount || 0) - (item.used || 0)).toFixed(2)
    }}</template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label width="80px">
                  <template #default="{ row: item }">
                    <el-popconfirm placement="top-start" title="确认删除吗!?" @confirm="
      form.historyProjects.splice(
        form.historyProjects.findIndex((p) => p == item),
        1
      )
      ">
                      <template #reference>
                        <el-button type="danger" icon="el-icon-delete" size="mini"></el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="考古内容">
              <el-form-item label="考古范围及规模" style="margin-bottom: 5px" prop="areaDesc">
                <el-input type="textarea" v-model="form.areaDesc" placeholder="（涉及面积，是否分期等）" rows="5"></el-input>
              </el-form-item>
              <el-form-item label="技术路线" style="margin-bottom: 5px" prop="techLine">
                <el-input type="textarea" v-model="form.techLine" placeholder="（拟采用的技术措施、方式、方法、材料等）"
                  rows="5"></el-input>
              </el-form-item>
              <!-- <el-form-item label="展示内容策划构想" prop="showIdear">
                <el-input type="textarea" v-model="form.showIdear" placeholder="（内容策划核心理念，中心主题，分项主题，展示方式，受众分析等）">
                </el-input>
              </el-form-item> -->
            </el-form-item>
            <el-form-item label="工程计划">
              <el-space>
                <el-form-item label="拟实施周期" prop="planDoPeroid">
                  <el-input v-model="form.planDoPeroid"></el-input>
                </el-form-item>
                <el-form-item label="完成时间" prop="planEndTime">
                  <el-date-picker v-model="form.planEndTime"></el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="计划对外开放时间" label-width="280px" prop="planOpenTime">
                  <el-date-picker v-model="form.planOpenTime"></el-date-picker>
                </el-form-item> -->
              </el-space>
            </el-form-item>
            <el-form-item label="考古目标预测" prop="resultReview">
              <el-input type="textarea" v-model="form.resultReview" placeholder="（简要说明考古预期效果）" rows="5"></el-input>
            </el-form-item>

            <el-form-item label="考古经费估算(万元)">
              <el-space>
                <el-form-item label="总经费" prop="forecastAmount">
                  <el-input v-model="form.forecastAmount"></el-input>
                </el-form-item>
                <el-form-item label="前期经费" prop="forecastBeginAmount">
                  <el-input v-model="form.forecastBeginAmount"></el-input>
                </el-form-item>
                <el-form-item label="工程实施费" prop="forecastDoAmount">
                  <el-input v-model="form.forecastDoAmount"></el-input>
                </el-form-item>
              </el-space>
            </el-form-item>
            <el-form-item label="资金来源(万元)">
              <el-space>
                <el-form-item label="拟申请国家（省级）财政经费" prop="sourceCenterAmount">
                  <el-input v-model="form.sourceCenterAmount"></el-input>
                </el-form-item>
                <el-form-item label="地方财政经费" prop="sourceLocalAmount">
                  <el-input v-model="form.sourceLocalAmount"></el-input>
                </el-form-item>
                <el-form-item label="其他" prop="sourceElseAmount">
                  <el-input v-model="form.sourceElseAmount"></el-input>
                </el-form-item>
              </el-space>
            </el-form-item>
            <el-form-item label="其他需要说明的情况" prop="otherDesc">
              <el-input v-model="form.otherDesc" placeholder="（为确保工程效果，地方政府拟实施的配套工程简介及落实情况，后续运行管理保障等）" type="textarea"
                rows="5"></el-input>
            </el-form-item>

            <!-- <el-form-item label="工程对象名称" prop="project_Name">
              <el-input v-model="form.project_Name" placeholder="（明确到拟实施项目的具体单元，如单体建筑、一组建筑群、一组院落等）"></el-input>
            </el-form-item>

            <el-form-item label="1.文保单位简介" prop="heritageUnitDesc">
              <el-input v-model="form.heritageUnitDesc"
                placeholder="（文物保护单位的基本情况的整体介绍，包括文物构成、地上文物分布、地下文物埋藏情况等；重点描述工程对象与文物保护单位的关系）"></el-input>
            </el-form-item>
            <el-form-item label="2.文物保护单位历次保护修缮及经费补助情况">
              <div style="margin-bottom: 10px;">
                <el-button type="primary" icon="el-icon-plus" @click="addHistoryFund">添加</el-button>
              </div>
              <el-table :data="form.historyFunds">
                <el-table-column label="年份" prop="year" width="120">
                  <template #default="{ row: item }">
                    <el-input v-model="item.year"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="工程对象" prop="name">
                  <template #default="{ row: item }">
                    <el-input v-model="item.name"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="维修或考古发掘内容" prop="content">
                  <template #default="{ row: item }">
                    <el-input type="textarea" v-model="item.content"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="经费补助（万元）" prop="amount" width="120">
                  <template #default="{ row: item }">
                    <el-input v-model="item.amount"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label width="80px">
                  <template #default="{ row: item }">
                    <el-popconfirm placement="top-start" title="确认删除吗!?"
                      @confirm="form.historyFunds.splice(form.historyFunds.findIndex(p => p == item), 1)">
                      <template #reference>
                        <el-button type="danger" icon="el-icon-delete" size="mini"></el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="3.工程项目内容" prop="projectWorkContent">
              <el-input type="textarea" rows="5" v-model="form.projectWorkContent"
                placeholder="（包括拟开展的前期工作、工程内容、范围、规模和拟采取的保护措施等）"></el-input>
            </el-form-item>
            <el-form-item label="4.工程立项的必要性" prop="projectNeedReason">
              <el-input type="textarea" rows="5" v-model="form.projectNeedReason"
                placeholder="（重点描述存在的主要问题及危害性，包括问题类型、特点、位置、面积、残损程度、危害性、影响因素等）"></el-input>
            </el-form-item>
            <el-form-item label="5.工程项目计划" prop="projectWorkPlan">
              <el-input type="textarea" rows="5" v-model="form.projectWorkPlan"
                placeholder="（包括前期工作安排、设计工作安排及保护工程实施工期估算等）"></el-input>
            </el-form-item>
            <el-form-item label="6.工程经费估算（万元）">
              <el-form-item label="工程经费总估算:" style="margin-bottom: 5px;" label-width="200px" prop="forecastAmount">
                <el-input v-model="form.forecastAmount" style="width:300px"></el-input>
              </el-form-item>
              <el-form-item label="申请省级文物保护补助经费:" label-width="200px" prop="forecastProvAmount">
                <el-input v-model="form.forecastProvAmount" placeholder="（其中前期勘察设计经费，工程实施经费）" style="width:300px">
                </el-input>
              </el-form-item>
            </el-form-item> -->
          </template>

          <el-form-item label="相关附件" prop="attachList">
            <div>(可根据实际情况另附文字说明、图纸等其他材料)</div>

            <FileuploadList :setting="fileuploadSetting" @upload="() => refForm.clearValidate('attachList')">
            </FileuploadList>
          </el-form-item>

          <el-form-item label="项目计划书" prop="setupFile">
            <FileuploadListV1 v-model:files="form.attachList" fileGroup="setupFile"
              accpet=".pdf,.wps,.ofd,.pdf,.doc,.docx" urlKey="filePath" nameKey="fileName"
              @upload="() => refForm.clearValidate('setupFile')">
            </FileuploadListV1>
          </el-form-item>


          <el-form-item label="批复文件" prop="setupDocId">
            <OffcialDocSelectAndCreate :category="4" v-model="form.setupDocId"></OffcialDocSelectAndCreate>
          </el-form-item>

          <el-form-item label="项目方案" prop="planFile">
            <FileuploadListV1 v-model:files="form.attachList" fileGroup="planFile"
              accpet=".pdf,.wps,.ofd,.pdf,.doc,.docx" urlKey="filePath" nameKey="fileName"
              @upload="() => refForm.clearValidate('planFile')">
            </FileuploadListV1>
          </el-form-item>

          <el-form-item label="编制单位" prop="contactPerson">
            <el-space>
              <el-input v-model="form.contactPerson" placeholder="联系人"></el-input>
              <el-input v-model="form.contactPhone" placeholder="电话"></el-input>
            </el-space>
          </el-form-item>
          <el-form-item label="填报时间">{{
            parseTime(form.writeDate, "{yyyy}年{mm}月{dd}日")
            }}</el-form-item>
          <!-- <el-row v-show="form.cata == 'planCreate'">
              <el-col :span="12">
                <el-form-item label="计划书类型">
                  <el-select v-model="form.planCreateType" placeholder="请选择计划书类型">
                    <el-option
                      v-for="item in planCreateTypeDic"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="计划书年份">
                  <el-select v-model="form.planCreateYear" placeholder="请选择计划书类型">
                    <el-option
                      v-for="item in planCreateYearDic"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="工程主要组成" v-show="form.projectType == '1005'">
              <el-checkbox-group v-model="form.safetyContains">
                <el-checkbox
                  v-for="item in projectSafetyContainsDic"
                  :label="item.value"
                  :key="item.value"
                >{{ item.label }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <el-row>
              <el-col :span="12">
                <el-form-item label="项目总概算(万元)">
                  <el-input v-model="form.totalAmount" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申请补助资金(万元)">
                  <el-input v-model="form.subsidyAmount" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="编制时间">
                  <el-date-picker v-model="form.writeDate" type="date" placeholder="选择日期"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="申报时间">
                  <el-date-picker v-model="form.applyDate" type="date" placeholder="选择日期"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="项目责任单位">
              <el-input v-model="form.responsibilityUnit" placeholder="请输入内容"></el-input>
            </el-form-item>

            <el-row>
              <el-col :span="12">
                <el-form-item label="联系人">
                  <el-input v-model="form.contactPerson" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话">
                  <el-input v-model="form.contactPhone" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="项目说明">
              <el-input v-model="form.memo" placeholder="请输入内容" type="textarea" :rows="3"></el-input>
            </el-form-item>
            <el-form-item label="工程保护对象及范围">
              <el-input
                :rows="3"
                v-model="form.projectObjectAndArea"
                placeholder="请输入内容"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-divider content-position="left">编制单位信息</el-divider>

            <el-form-item label="单位名称">
              <el-input v-model="form.writeUnitName" placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="资质证号">
                  <el-input v-model="form.writeUnitNo" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="项目负责人">
                  <el-input v-model="form.writeUnitPerson" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="资质等级"></el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="合同金额(万元)">
                  <el-input v-model="form.writeUnitContractAmount" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="法定代表人">
                  <el-input v-model="form.writeUnitRightPerson" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话">
                  <el-input v-model="form.writeUnitPhone" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="地址">
              <el-input type="textarea" v-model="form.writeUnitAddress" placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-divider content-position="left">附件信息</el-divider>

            <template v-if="form.cata == 'projectCreate'">
              <el-form-item label="上传计划书报告"></el-form-item>
            </template>
            <template v-else>
              <el-form-item label="计划书"></el-form-item>
              <el-form-item label="申请文件扫描件"></el-form-item>
            </template>

            <el-divider content-position="left">请示文件</el-divider>
            <el-form-item label="上传请示文件"></el-form-item>-->
          <!-- <el-form-item>
        <el-button type="primary" @click="onSubmit">下一步</el-button>
        <el-button>取消</el-button>
            </el-form-item>-->
        </el-tab-pane>

        <el-tab-pane label="操作记录" name="logs" :disabled="!logs || logs.length == 0">
          <LogHistory :data="logs"></LogHistory>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    </Base>

    <div class="pageaa-footer">
      <div class="button">
        <el-button icon="el-icon-back" @click="$router.push(redirectUrl)">返回</el-button>
        <!-- 
        <el-divider direction="vertical"></el-divider>
        <el-button icon="el-icon-arrow-right" @click="nextTo()" v-if="activeName == 'first'" type="primary">下一步
        </el-button>

        <template v-if="activeName == 'second'">
          <el-button icon="el-icon-arrow-left" @click="activeName = 'first';" type="primary">上一步</el-button>
          <el-button type="success" icon="el-icon-check" @click="doSave(false)" :loading="isLoading || isFileUploading">
            保 存</el-button>
          <el-popconfirm title="确认提交吗！？" @confirm="doSave(true)" placement="top" v-if="form.status == 0">
            <template #reference>
              <el-button type="primary" :loading="isLoading || isFileUploading">
                {{
                    isLoading ? "处理中" : "提交"
                }}
              </el-button>
            </template>
          </el-popconfirm>
        </template> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import OffcialDocSelectAndCreate from "@/views/officialDoc/offcialDocSelectAndCreate.vue";
import FileuploadListV1 from "@/components/fileuploadListV1.vue";
import FileuploadList from "@/components/fileuploadList.vue";
import { useCityRelTown } from "@/network/chinaArea";
import { parseTime } from "@/utils/index.js";
import {
  dicLabel,
  useSelectItemList,
  useSelectItemWithChildList,
  scheduleTypeDic,
} from "@/network/lookUp.ts";
import SelectUnit from "@/views/heritageUnit/selectUnit.vue";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRef,
  inject,
} from "vue";
import Base from "@/views/layout/Base.vue";
import { useSetupProject, useQueryActionHistory } from "@/network/setupProject";
import roles from "@/types/roles.ts";
import { useFindHeritage } from "@/network/heritageUnit";
import { useRouter, useRoute } from "vue-router";

import LogHistory from "@/views/shared/logHistory.vue";
export default defineComponent({
  name: "projectCreate",
  components: { Base, SelectUnit, FileuploadList, LogHistory,FileuploadListV1,OffcialDocSelectAndCreate },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    // const form: any = reactive({
    //   code: "待编号",
    //   project_Name: "",
    //   historyProjects: [],
    //   writeDate: new Date(),
    //   attachList: [],
    //   safeProjectType: []
    // });
    const router = useRouter();
    const route = useRoute();
    const redirectUrl = ref(
      (route.query.redirect || "/ReportAndReview/ProvSetup").toString()
    );
    const user: any = inject("user");
    const refForm = ref(null);

    const id: any = ref(props.id);
    const isFileUploading = ref(false);
    const [isLoading, form, find, save, , review] = useSetupProject(id);
    const [logs, queryHistory] = useQueryActionHistory(id);
    const [, , findHeritage] = useFindHeritage();

    const fileuploadSetting = reactive({
      accpet: ".pdf,.jpg,.bmp,.gif,.tif,.zip,.rar,.xls,.xlsx,.doc,.docx",
      urlKey: "filePath",
      nameKey: "fileName",
      isFileUploading,
      typeGroup: ref({
        list: form.attachList,
        type: "附件",
      }),
    });

    const selectUnitSetting = reactive({
      visible: false,
      disabled: false,
      heritage_Name: "",
    });
    const [dicCity, dicTown] = useCityRelTown(
      toRef(form, "cityId"),
      toRef(form, "countryId")
    );

    const heritageLevelDic = useSelectItemList("HeritageLevel");
    const dicHeritageBatch = useSelectItemList("HeritageBatch");
    const dicHeritageClass = useSelectItemList("HeritageClass");
    const [project_ClassifyDic, project_SubClassifyDic] =
      useSelectItemWithChildList(
        toRef(form, "project_Classify_Id"),
        toRef(form, "project_SubClassify_Id"),
        "ProjectClassify",
        false
      );

    const title = computed(() => {
      let str = currentHeritage.value.heritage_Name || "";
      str += ` ${dicLabel(form.projectType, scheduleTypeDic) || ""}计划书`;
      return `[${form.code}] ${str}  `;
    });
    const activeName = ref("second");

    const historyFunds = ref([]);
    const currentHeritage: any = ref({});
    const selectUnitChange = (item) => {
      currentHeritage.value = item;
      (form.heritageBatch_ID = item.heritageBatch_ID),
        (form.heritage_ManageOrg = item.heritage_ManageOrg);
      form.heritage_OwnerSuperior = item.heritage_OwnerSuperior;
      form.cityId = item.city_ID;
      form.countryId = item.county_ID;
    };

    const addHistoryProject = () => {
      form.historyProjects.push({});
    };

    const addHistoryFund = () => {
      form.historyFunds.push({});
    };

    const onReviewSubmit = (result) => {
      //console.log(result);
      review(form.id, result).then((p) => {
        emit("change");
        // emit("submit", result);
        router.push(redirectUrl.value);
      });
    };

    const doSave = (submit) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            if (submit) {
              if (user.inRoles(roles.city)) form.status = 2;
              else {
                form.status = 1;
              }
            } else {
              form.status = 0;
            }
            save().then(() => {
              id.value = form.id;
              emit("change");
              if (submit) {
                emit("submit");
                router.push(redirectUrl.value);
              }
              resolve("success");
            });
          } else {
            reject("error");
          }
        });
      });
    };

    const nextTo = () => {
      const errs = [];
      refForm.value.validateField(
        [
          "heritageUnit_Id",
          "heritageBatch_ID",
          "isRevolution",
          "isSafeProject",
          "safeProjectType",
          "projectType",
          "cityId",
          "countryId",
          "address",
          "heritage_ManageOrg",
          "heritage_OwnerSuperior",
        ],
        (valid) => {
          if (valid !== "") {
            errs.push(valid);
          }
        }
      );
      setTimeout(() => {
        if (errs.length == 0) {
          activeName.value = "second";
        }
      }, 1);
    };
    find().then((p) => {
      //setTimeout(() => refForm.value.clearValidate(), 1);
      selectUnitSetting.heritage_Name = p.heritageUnitName;
      if (p.heritageUnit_Id) {
        findHeritage(p.heritageUnit_Id).then((res) => {
          currentHeritage.value = res;
        });
      }
      fileuploadSetting.typeGroup.list = form.attachList;

      //console.log(p);
      // console.log(form, p);
      queryHistory();
    });

    const rules = {
      heritageUnit_Id: [
        { required: true, message: "请输入文保单位！", trigger: "blur" },
      ],
      heritageBatch_ID: [
        { required: true, message: "请输入批次！", trigger: "change" },
      ],
      isRevolution: [
        { required: true, message: "请输入是否革命文物！", trigger: "change" },
      ],
      isSafeProject: [
        { required: true, message: "请输入工程类型！", trigger: "change" },
      ],
      safeProjectType: [
        { required: true, message: "请输入工程类型！", trigger: "change" },
      ],
      projectType: [
        { required: true, message: "请输入工程类型！", trigger: "change" },
      ],
      cityId: [{ required: true, message: "请输入市级！", trigger: "change" }],
      countryId: [
        { required: true, message: "请输入区县！", trigger: "change" },
      ],
      address: [{ required: true, message: "请输入村镇！", trigger: "blur" }],
      heritage_ManageOrg: [
        { required: true, message: "请输入使用管理单位！", trigger: "blur" },
      ],
      heritage_OwnerSuperior: [
        { required: true, message: "请输入上级主管部门！", trigger: "blur" },
      ],
      project_Name: [
        { required: true, message: "请输入项目名称！", trigger: "blur" },
      ],
      heritageUnitDesc: [
        {
          required: true,
          message: "请输入文物保护单位简况！",
          trigger: "blur",
        },
      ],
      projectNeedReason: [
        { required: true, message: "工程实施必要性", trigger: "blur" },
      ],
      projectProtectArea: [
        { required: true, message: "请输入工程防护范围", trigger: "blur" },
      ],
      projectProtectObject: [
        { required: true, message: "请输入工程防护对象", trigger: "blur" },
      ],
      forecastAmount: [{ required: true, message: "请输入", trigger: "blur" }],
      forecastAmountReason: [
        { required: true, message: "请输入测算依据", trigger: "blur" },
      ],
      projectForecastDoThings: [
        { required: true, message: "请输入拟采取的具体措施", trigger: "blur" },
      ],
      projectBaseThingsInfo: [
        { required: true, message: "请输入原有设施简况", trigger: "blur" },
      ],
      projectDoCondition: [
        { required: true, message: "请输入项目实施保障条件", trigger: "blur" },
      ],
      isInProtectPlan: [
        {
          required: true,
          message: "请输入是否纳入保护规划",
          trigger: "change",
        },
      ],
      isDoArcheology: [
        {
          required: true,
          message: "请输入是否开展过考古工作",
          trigger: "change",
        },
      ],
      isDoResearch: [
        {
          required: true,
          message: "请输入是否开展过前期勘察或研究",
          trigger: "change",
        },
      ],
      areaDesc: [
        { required: true, message: "请输入工程范围及规模", trigger: "blur" },
      ],
      techLine: [
        { required: true, message: "请输入工程技术路线", trigger: "blur" },
      ],
      showIdear: [
        {
          required: true,
          message: "请输入展示内容策划构想（限展示工程）",
          trigger: "blur",
        },
      ],
      planDoPeroid: [
        { required: true, message: "请输入拟实施周期", trigger: "blur" },
      ],
      planEndTime: [
        { required: true, message: "请输入计划竣工时间", trigger: "blur" },
      ],
      planOpenTime: [
        {
          required: true,
          message: "请输入计划对外开放时间（限展示工程）",
          trigger: "blur",
        },
      ],
      resultReview: [
        {
          required: true,
          message: "请输入工程效果预测及文物影响评估",
          trigger: "blur",
        },
      ],
      landCondition: [
        { required: true, message: "请输入土地现状", trigger: "blur" },
      ],
      landOwnType: [
        { required: true, message: "请输入土地权属", trigger: "change" },
      ],
      landManager: [
        { required: true, message: "请输入实际管理部门", trigger: "blur" },
      ],

      landPropType: [
        { required: true, message: "请输入土地性质", trigger: "change" },
      ],
      landPropMemo: [
        { required: true, message: "请输入土地性质备注", trigger: "blur" },
      ],

      forecastBeginAmount: [
        { required: true, message: "请输入前期经费（万元）", trigger: "blur" },
      ],
      forecastDoAmount: [
        {
          required: true,
          message: "请输入工程实施费（万元）",
          trigger: "blur",
        },
      ],
      sourceLocalAmount: [
        {
          required: true,
          message: "请输入地方财政经费（万元）",
          trigger: "blur",
        },
      ],
      sourceCenterAmount: [
        { required: true, message: "请输入国家省级（万元）", trigger: "blur" },
      ],
      sourceElseAmount: [
        { required: true, message: "请输入其他（万元）", trigger: "blur" },
      ],
      otherDesc: [
        {
          required: true,
          message: "请输入其他需要说明的情况",
          trigger: "blur",
        },
      ],
      projectWorkContent: [
        { required: true, message: "请输入工程项目内容", trigger: "blur" },
      ],
      projectWorkPlan: [
        { required: true, message: "请输入工程项目计划", trigger: "blur" },
      ],
      forecastProvAmount: [
        {
          required: true,
          message: "请输入申请省级文物保护补助经费",
          trigger: "blur",
        },
      ],
      contactPerson: [
        {
          required: true,
          message: "请输入联系人及电话",
          trigger: "blur",
          validator: (rule: any, value: any, callback: any) => {
            if (
              !form.contactPerson ||
              !form.contactPhone ||
              form.contactPerson === "" ||
              form.contactPhone === ""
            ) {
              callback(new Error("请输入联系人及电话"));
            } else {
              callback();
            }
          },
        },
      ],
      attachList: [
        {
          required: true,
          message: "附件是必填项!",
          trigger: "blur",
          validator: (rule: any, value: any, callback: any) => {
            if (!form.attachList || form.attachList.length == 0) {
              callback(new Error("附件是必填项!"));
            } else {
              callback();
            }
          },
        },
      ],
    };

    return {
      redirectUrl,
      isFileUploading,
      nextTo,
      rules,
      logs,
      onReviewSubmit,
      user,
      roles,
      isLoading,
      refForm,
      doSave,
      historyFunds,
      fileuploadSetting,
      parseTime,
      addHistoryProject,
      addHistoryFund,
      scheduleTypeDic,
      dicHeritageClass,
      dicHeritageBatch,
      dicCity,
      dicTown,
      currentHeritage,
      activeName,
      title,
      form,
      selectUnitChange,
      selectUnitSetting,
      dicLabel,
      heritageLevelDic,
      project_ClassifyDic,
      project_SubClassifyDic,
    };
  },
});
</script>

<style lang="scss" scoped></style>
